window.addEventListener("scroll", function () {
  // ヘッダーを変数の中に格納する
  const hBar = document.querySelector(".h-bar__menu-ul ");
  if (window.scrollY > 100) {
    hBar.classList.add('scroll-nav');
    hBar.classList.remove('scroll-none');
  } else {
    hBar.classList.add('scroll-none');
    hBar.classList.remove('scroll-nav');
  }

  //スライドインのアニメーション
  var elements = document.querySelectorAll('.slidein');

  elements.forEach(function(element) {
      var elemPos = element.offsetTop,
          scroll = window.scrollY,
          windowHeight = window.innerHeight;

      if (scroll > elemPos - windowHeight + 150) {
          element.classList.add('scrollin');
      }
  });
});